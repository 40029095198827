import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Heading from "components/Typography/Header";
import Button from "components/Button";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import swal from 'sweetalert';
import {
  retailCreate,
  reset
} from "redux/reseller/retails/retailsSlice";
import { retailCheck, resetCheck } from "redux/reseller/retails/retailsCheckSlice";
import { useCustomSettings } from 'hooks/useCustomSettings'
import Popup from "components/Popup";
import { useUUID } from "hooks/useUUID";

const keyTextMapping = {
  base_diamond_price_usd: "1 Adet Elmas Fiyatı ($)",
  usdtry_exchange_rate: "1 Dolar",
}


const OrderRetailCreateContainer = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const dispatchCheck = useDispatch();
  const [trigger, setTrigger] = useState(0);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [lastFormValues, setLastFormValues] = useState(null);
  const [lastOrderData, setLastOrderData] = useState(null);
  const {
    isError,
    isLoading,
    isSuccess,
    message,
    retail
  } = useSelector((state) => state.resellerRetails);
  const {
    isError : isErrorCheck,
    isLoading : isLoadingCheck, 
    isSuccess : isSuccessCheck,
    message : messageCheck,
    retailCheck : retailCheckData
  } = useSelector((state) => state.resellerRetailsCheck);
  const { data } = useSelector(state => state.resellerSettings);
  const uuid = useUUID(trigger);

  const profile = useSelector((state) => state.resellerProfile.profile);
  const diamondPrice = useCustomSettings(data, keyTextMapping, 'base_diamond_price_usd');
  const exchangeRate = useCustomSettings(data, keyTextMapping, 'usdtry_exchange_rate');

  const createOrderForm = Yup.object().shape({
    bigoUsername: Yup.string().required("Kullanıcı ID gerekli"),
    diamondAmount: Yup.number().required("Elmas Miktarı Gerekli")
  });

  const tryAmount = (diamondAmount) => {
    let amount = diamondAmount * diamondPrice?.value * exchangeRate?.value
    amount = Math.ceil(amount / (1 - profile?.retail_commission_rate/100))
    return amount
  }

  useEffect(() => {
    if (isError) {
      toast.error(message);
    }
    if(retail.need_verify){
      const el = document.createElement("div");
      let htmlmsg = retail.message.replace(/kullanıcı: (.*?), /g, 'kullanıcı: <b>$1</b>, ');
      htmlmsg = htmlmsg.replace(/elmas: (.*?), /g, 'elmas: <b>$1</b>, ');
      el.innerHTML += `<img src="${retail.avatar}" style="width:92px;margin:auto;border-radius: 50%;" alt="avatar" />`;
      el.innerHTML += `${htmlmsg}`;
      swal({
        title: "Profil doğrulama",
        content: {
          element: el
        },
        icon: "info",
        buttons: ["İptal", "Onayla"]
      })
      .then(confirmed => {
        if (confirmed) {
          dispatch(
            retailCreate({
              bigo_username: lastFormValues.bigoUsername,
              diamonds_amount: lastFormValues.diamondAmount,
              mico_verified: true,
              nonce: uuid
            })
          );
        }
      });
    }
    else if (isSuccess) {
      setIsPopupOpen(true);
      setLastOrderData(retail);
      lastFormValues.resetForm();
      setTrigger((prev) => prev + 1);
    }
    return () => {
      dispatch(reset());
    };
  }, [isSuccess, isError, message, dispatch]);

  useEffect(() => {
    if (isErrorCheck) {
      toast.error(messageCheck);
    } else if (isSuccessCheck) {
      toast.success("Yükleme için yeterli elmas mevcut.");
    }
    return () => {
      dispatchCheck(resetCheck());
    }
  }, [isSuccessCheck, isErrorCheck, dispatchCheck]);

  const handleSubmit = async (values, {resetForm}) => {
    setLastFormValues({...values, resetForm});
    
    dispatch(
      retailCreate({
        bigo_username: values.bigoUsername,
        diamonds_amount: values.diamondAmount,
        nonce: uuid
      })
    );
    
  };


  return (
    <div className="p-4 ">
      <Heading className="text-white-100" level={5}>
        ID ile Elmas Yükle
      </Heading>
      <div className="p-4 bg-gray-900 my-4 rounded-xl min-h-screen">
    
        <div className="grid grid-cols-1 md:grid-cols-1 gap-4">
       
          <div className="p-4 bg-black md:h-screen">
            <Formik
              initialValues={{
                bigoUsername: "",
                diamondAmount: "",
                mico_verified: false
              }}
              validationSchema={createOrderForm}
              onSubmit={handleSubmit}
            >
              {({ errors, touched, setFieldValue, values }) => {


                return (
                  (
                    <Form className="space-y-4">
                       <div className="text-white-100 font-medium mb-4">Kullanılabilir Toplam Bakiye: {profile?.balance_try + profile?.allowance_try} ₺</div>
                      <div className="">
                        <span className="text-white-100 font-medium">
                         Kullanıcı ID 
                        </span>
                        <Field
                          name="bigoUsername"
                          type="text"
                          className="w-full font-medium bg-white-100 rounded-lg appearance-none focus:outline-none  p-2 mt-2"
                        />
                        <ErrorMessage
                          name="bigoUsername"
                          component="div"
                          className="text-red-500"
                        />
                      </div>

                      <div className="">
                        <span className="text-white-100 font-medium">
                         Elmas Miktarı
                        </span>
                        <Field
                          name="diamondAmount"
                          type="number"
                          className="w-full font-medium bg-white-100 rounded-lg appearance-none focus:outline-none  p-2 mt-2"
                        />
                        <ErrorMessage
                          name="diamondAmount"
                          component="div"
                          className="text-red-500"
                        />
                      </div>

                      <Button
                        type="submit"
                        size="small"
                        loading={isLoading}
                        disabled={isLoading || isLoadingCheck}
                        className="bg-primary"
                      >
                        Sipariş Oluştur
                      </Button>
                      <div className="flex flex-col space-y-4 mt-4">
                        <span className="block mt-2 text-primary font-medium">Komisyon: %{profile.retail_commission_rate}</span>
                        <span className="block mt-2 text-primary font-medium">Net Tutar: {tryAmount(values.diamondAmount)}₺</span>
                        <span className="block mt-2 text-primary font-medium">İşlem Sonu Toplam Bakiyeniz: {(profile?.balance_try + profile?.allowance_try) - tryAmount(values.diamondAmount)}₺</span>
                      </div>
                    </Form>
                  )
                )
              }}
            </Formik>
           
          </div>
       

        </div>
      </div>
      <Popup isOpen={isPopupOpen} onClose={() => setIsPopupOpen(false)}>
        <div>
          <Heading className="text-primary text-center" level={5}>
            Başarılı
          </Heading>
          <p className="text-white-100 mt-4 text-xl font-semibold">
            Sipariş Başarıyla Oluşturuldu.<br />
            {lastOrderData?.transfer_done && (
              <span className="text-green-500">Yükleme tamamlandı.</span>
            )}
          </p>
        </div>
      </Popup>
    </div>
  );
};

/*

                      <Button
                        type="button"
                        size="small"
                        loading={isLoadingCheck}
                        disabled={isLoading || isLoadingCheck}
                        onClick={async () => {
                          dispatchCheck(
                            retailCheck({
                              diamonds_amount: values.diamondAmount
                            })
                          );
                        }}
                        className="bg-gray-300 ml-2"
                      >
                        Yüklenebilir Elmas Sorgula
                      </Button>
*/
export default OrderRetailCreateContainer;
