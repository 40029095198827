import React, { useEffect, useState, useRef } from "react";
import NotificationSound from "notification/sound.mp3";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from 'react-router'
import { orderFinalize, reset, orders as getOrders } from "redux/admin/orders/ordersSlice";
import { downloadFile } from "redux/admin/downloadFile/downloadFileSlice";

import moment from "moment";
import "moment/locale/tr";

moment.locale("tr");

const Orders = ({ data,inputSearch,setInputSearch }) => {
  const audioRef = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isFinalizeSuccess, isFinalizeError, isFinalizeLoading, message } =
    useSelector((state) => state.adminOrders);
  const [prevOrderCount, setPrevOrderCount] = useState(null);
  const [ordersData, setOrdersData] = useState([]);

  useEffect(() => {
    setOrdersData(data);
  }, [data]);

  useEffect(() => {
    dispatch(getOrders());
  }, [dispatch]);

  useEffect(() => {
    if (prevOrderCount !== null && data?.length > prevOrderCount) {

      audioRef.current.play();
    }
    setPrevOrderCount(data?.length);
  }, [ordersData, prevOrderCount]);

  useEffect(() => {
    if (isFinalizeSuccess) {

      navigate(0)
    }
    if (isFinalizeError) {
      navigate(0)
    }
    return () => {
      dispatch(reset());
    };
  }, [isFinalizeSuccess, isFinalizeError]);

  const [filter, setFilter] = useState("pending");
  const [search, setSearch] = useState("");

  const handleFilterChange = (value) => {
    setFilter(value);
  };

  const handleSearchChange = (event) => {
    setInputSearch(event.target.value);
  };

  const filteredData = (ordersData || []).filter(
    (item) =>
      item.status.includes(filter) &&
      (item.customer.full_name.toLowerCase().includes(search.toLowerCase()))
  );

  const handleFinalize = (id, status) => {
    dispatch(orderFinalize({ order_id: id, status: status }));
  };

  const downloadFileHandler = (id) => {
    dispatch(downloadFile({ file: id }));
  };

  return (
    <>
      <audio ref={audioRef} src={NotificationSound} />
      <div className="flex flex-col md:flex-row md:justify-between">
        <input
          type="text"
          className="mx-4 px-2 py-1 rounded-md"
          placeholder="Kullanıcı Ara"
          value={inputSearch}
          onChange={handleSearchChange}
        />
        <div className="mx-4 mt-4 md:mt-0 text-white-100">
          <div className="flex gap-2 justify-between ">
            <div
              onClick={() => handleFilterChange("pending")}
              className="bg-yellow-50 text-yellow-800 px-2 py-1 rounded-md cursor-pointer"
            >
              Onay Bekleyenler
            </div>
            <div
              onClick={() => handleFilterChange("completed")}
              className="bg-green-50 text-green-700 rounded-md px-2 py-1 cursor-pointer"
            >
              Onaylananlar
            </div>

          </div>
        </div>
      </div>
      <div className="overflow-x-auto p-4 grid grid-cols-1 md:grid-cols-4 gap-4">
        {filteredData.map((data) => (
          <div
            key={data.id}
            className="flex bg-gray-900 flex-col p-3 relative text-white-100 rounded-md"
          >
            <h2>
              Ad Soyad: {data.customer.full_name === "TCKN YOK" ? data.payment.full_name : data.customer.full_name}
            </h2>
            <h2>Bigo Kullanıcı Adı: {data.bigo_username}</h2>
            <h2>Yatırılan Tutar: {Intl.NumberFormat("tr-TR",{currency:"TRY",style:"currency"}).format(data.payment.amount_try)}</h2>
            <h2>Elmas Miktarı: {Intl.NumberFormat("tr-TR").format(data.amount_diamonds)}</h2>
            {data.message && <h2>Ek Bilgi: {data.message}</h2>}
            <h2>Sorgu No: {data.payment.reference1}</h2>
            <h2>Bayi Adı: {data.payment.reference4}</h2>
            {data.invoice &&  <h2 onClick={() =>{downloadFileHandler(data?.invoice?.file_name)}} className="bg-primary px-2 py-1 absolute text-sm rounded-lg right-2 top-2 cursor-pointer">Faturayı İndir</h2>}
            <h2>İşlem Tarihi: {moment(data.created_at).format("LLL")}</h2>
            {
              filter === "pending" && (<div className="flex mt-4">
                <div
                  onClick={() => handleFinalize(data.id, "completed")}
                  className="text-white-100 cursor-pointer bg-primary px-2 py-2 flex items-center justify-center w-full"
                >
                  Onayla
                </div>

              </div>)
            }
          </div>
        ))}
      </div>
    </>
  );
};

export default Orders;

/*

 <table className="min-w-full divide-y-2 divide-gray-200 text-sm">
        <thead className="">
          <tr>
            <th className="whitespace-nowrap text-left px-4 py-2 font-medium text-white-100">
              Ad Soyad
            </th>

            <th className="whitespace-nowrap text-left px-4 py-2 font-medium text-white-100">
              Bigo Kullanıcı Adı
            </th>

            <th className="whitespace-nowrap text-left px-4 py-2 font-medium text-white-100">
              Yatırılan Tutar
            </th>
            <th className="whitespace-nowrap text-left px-4 py-2 font-medium text-white-100">
              Elmas Miktarı
            </th>
            <th className="whitespace-nowrap text-left px-4 py-2 font-medium text-white-100">
              İşlem Tarihi
            </th>
            <th className="whitespace-nowrap text-left px-4 py-2 font-medium text-white-100">
              İşlem Durumu
            </th>
            <th className="whitespace-nowrap text-left px-4 py-2 font-medium text-white-100">
              İşlem
            </th>
          </tr>
        </thead>

        <tbody className="divide-y divide-gray-200">
          {data?.map((data) => (
            <tr key={data.id}>
              <td className="whitespace-nowrap px-4 py-2 font-medium text-white-100">
                {data.customer.first_name} {data.customer.last_name}
              </td>
              <td className="whitespace-nowrap px-4 py-2 font-medium text-white-100">
                {data.bigo_username}
              </td>
              <td className="whitespace-nowrap px-4 py-2 text-white-100">
                {data.payment.amount_try} ₺
              </td>
              <td className="whitespace-nowrap px-4 py-2 text-white-100">
                {data.amount_diamonds}
              </td>
              <td className="whitespace-nowrap px-4 py-2 text-white-100">
                {moment(data.created_at).format('Do MMMM YYYY, H:mm:ss')}
              </td>
              <td className="whitespace-nowrap px-4 py-2 text-white-100">
                {data.status === "completed" ? (
                  <OrderIndicator color="green">Gönderim Yapıldı.</OrderIndicator>
                ) : data.status === "pending_transfer" ? (
                  <OrderIndicator color="yellow">Gönderim Bekliyor.</OrderIndicator>
                ) : data.status === "failed" ? (
                  <OrderIndicator color="red">
                    Gönderim Reddedildi.
                  </OrderIndicator>
                ) : (
                  <OrderIndicator color="red">No Data</OrderIndicator>
                )}
              </td>
              <td className="whitespace-nowrap px-4 py-2">
                <Link
                  to={`/admin/orders/detail/${data.id}`}
                  className="inline-block rounded bg-indigo-600 px-4 py-2 text-xs font-medium text-white-100 hover:bg-indigo-700"
                >
                  Detaylı Gör
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>


*/
