import React, { useEffect, useState } from "react";
import { RxSketchLogo } from "react-icons/rx";
import { GiReceiveMoney, GiDiamondTrophy } from "react-icons/gi";
import { BsWallet2 } from "react-icons/bs";

import { useSelector, useDispatch } from "react-redux";
import { getTotalSales } from "redux/reseller/total-sales/totalSalesSlice";
import { settings } from "redux/reseller/settings/settingsSlice";
import { profile } from "redux/reseller/profile/profileSlice";
import moment from "moment";
import "moment/locale/tr";
moment.locale("tr");


const TopPanel = () => {
  const dispatch = useDispatch();

  const [startDateTotalSales, setStartDateTotalSales] = useState(() => {
    const date = new Date();
    date.setUTCDate(1);
    return date.toISOString().slice(0, 10);
  });

  const [endDateTotalSales, setEndDateTotalSales] = useState(() => {
    const date = new Date();
    date.setUTCMonth(date.getUTCMonth() + 1);
    date.setUTCDate(1);
    return date.toISOString().slice(0, 10);
  });

  useEffect(() => {
    dispatch(settings());
    dispatch(profile());
  }, [dispatch]);

  useEffect(() => {
    const utcMinusThreeHours = -3 * 60 * 60 * 1000;
    const startDateTime = (date) => {
      return new Date(`${date}T00:00:00.000Z`).getTime() + utcMinusThreeHours;
    };
    const endDateTime = (date) => {
      return new Date(`${date}T23:59:00.000Z`).getTime() + utcMinusThreeHours;
    };

    dispatch(
      getTotalSales({
        start: new Date(startDateTime(startDateTotalSales)).toISOString(),
        end: new Date(endDateTime(endDateTotalSales)).toISOString(),
      })
    );
  }, [startDateTotalSales, endDateTotalSales,dispatch]);

  const { profile: profileData } = useSelector(
    (state) => state.resellerProfile
  );
  const { data: totalSales } = useSelector((state) => state.resellerTotalSales);

  return (
    <div>
      <div className="flex gap-4 px-4 items-center">
        <input
          type="date"
          value={startDateTotalSales}
          onChange={(e) => setStartDateTotalSales(e.target.value)}
          className="rounded-lg px-3 py-2 mx-2"
        />
        <span className="text-white-100 text-xl">-</span>
        <input
          type="date"
          value={endDateTotalSales}
          onChange={(e) => setEndDateTotalSales(e.target.value)}
          className="rounded-lg px-3 py-2 mx-2"
        />
      </div>
      <div className="grid lg:grid-cols-4 gap-4 p-4">
        <div className="lg:col-span-1 col-span-1 bg-gray-900 flex justify-between w-full p-4 rounded-lg">
          <div className="flex items-center gap-4 rounded-lg p-6">
            <span className="rounded-full bg-primary p-3 text-white-100">
              <GiDiamondTrophy size={30} />
            </span>

            <div>
              <p className="text-2xl font-medium text-white-100">

                {Intl.NumberFormat("tr-TR", {}).format(
                    totalSales?.total_diamond_sales || 0
                    )}
              </p>

              <p className="text-base text-white-100">Satılan Elmas</p>
            </div>
          </div>
        </div>
        <div className="lg:col-span-1 col-span-1 bg-gray-900 flex justify-between w-full p-4 rounded-lg">
          <div className="flex items-center gap-4 rounded-lg p-6">
            <span className="rounded-full bg-primary p-3 text-white-100">
              <GiReceiveMoney size={30} />
            </span>

            <div>
              <p className="text-2xl font-medium text-white-100">
                {
                  Intl.NumberFormat("tr-TR", {
                    style: "currency",
                    currency: "TRY",
                  }).format(totalSales?.total_try_sales || 0)
                }
              </p>

              <p className="text-base text-white-100">Kazanç</p>
            </div>
          </div>
        </div>
        <div className="lg:col-span-1 col-span-1 bg-gray-900 flex justify-between w-full p-4 rounded-lg">
          <div className="flex items-center gap-4 rounded-lg p-6">
            <span className="rounded-full bg-primary p-3 text-white-100">
              <RxSketchLogo size={30} />
            </span>

            <div>
              <p className="text-2xl font-medium text-white-100">
                {profileData?.calculator_diamond_price || 0}
              </p>

              <p className="text-base text-white-100">
                1₺ karşılığında sattığınız elmas miktarı
              </p>
            </div>
          </div>
        </div>

        <div className="lg:col-span-1 col-span-1 bg-gray-900 flex justify-between w-full p-4 rounded-lg">
          <div className="flex items-center gap-4 rounded-lg p-6">
            <span className="rounded-full bg-primary p-3 text-white-100">
              <BsWallet2 size={30} />
            </span>

            <div>
              <p className="text-2xl font-medium text-white-100">
                {
                    Intl.NumberFormat("tr-TR", {
                        style: "currency",
                        currency: "TRY",
                    }).format(profileData?.balance_try || 0)
                }
              </p>

              <p className="text-xs text-white-100">
                {
                  Intl.NumberFormat("tr-TR", {
                    style: "currency",
                    currency: "TRY",
                  }).format(profileData?.allowance_try || 0)
                }₺ kadar eksiye düşebilirsiniz.
              </p>
              <p className="text-base text-white-100">Kasa</p>
            </div>
          </div>
        </div>


{profileData?.mico_stats && (
<div className="lg:col-span-1 col-span-1 bg-gray-900 flex justify-between w-full p-4 rounded-lg">
  <div className="flex items-center gap-4 rounded-lg p-6">
    <span className="rounded-full bg-primary p-3 text-white-100">
      <GiDiamondTrophy size={30} />
    </span>

    <div>
      <p className="text-2xl font-medium text-white-100">
        {(profileData?.mico_stats?.surplus ?? -1).toLocaleString('en-US')}
      </p>

      <p className="text-base text-white-100">Bigo coins</p>
    </div>
  </div>
</div>)}

{profileData?.mico_stats && (
<div className="lg:col-span-1 col-span-1 bg-gray-900 flex justify-between w-full p-4 rounded-lg">
  <div className="flex items-center gap-4 rounded-lg p-6">
    <span className="rounded-full bg-primary p-3 text-white-100">
      <GiDiamondTrophy size={30} />
    </span>

    <div>
      <p className="text-base text-white-100">Son 24s yüklemeler</p>

      {profileData?.mico_stats?.charge_history && profileData?.mico_stats?.charge_history?.length > 0 ? <p className="text-xs text-white-100">
        {profileData?.mico_stats?.charge_history?.map((charge, index) => {
          let ago = moment(charge.received_time * 1000).fromNow();
          return (
            <span key={index}>
              <b>{charge.diamonds}</b> elmas, <b>{charge.bigo_id}</b>, <span style={{ color: charge.status == 2 ? '#198754' : '' }}>{charge.status == 2 ? 'başarılı' : charge.status}</span>, {ago} <br />
            </span>
          );
        })}
      </p> : ''}
    </div>
  </div>
</div>)}


      </div>
    </div>
  );
};

export default TopPanel;
