import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import retailsService from './retailsService';
export const retailCheck = createAsyncThunk('reseller/orders/create/retailbalancecheck', async (data, {rejectWithValue}) => {
    try {
        const response = await retailsService.checkRetailBalance(data);
        return response;
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return rejectWithValue(message);
    }
});
const initialState = {
    isError:false,
    isLoading:false,
    isSuccess:false,
    message: '',
    retail:{}
};
export const retailsCheckSlice = createSlice({
    name: 'resellerRetailsCheck',
    initialState,
    reducers: {
        reset: (state) => {
            state.isError = false;
            state.isSuccess = false;
            state.isLoading = false;
            state.message = '';
            state.retailCheck = {};
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(retailCheck.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(retailCheck.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.retail = action.payload;
            })
            .addCase(retailCheck.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
            });
      
    }
})
export const {
    reset : resetCheck
} = retailsCheckSlice.actions;
export default retailsCheckSlice.reducer;
