import React, { useEffect } from "react";
import OrderIndicator from "components/OrderIndicator";
import { useDispatch, useSelector } from "react-redux";
import { finalizeTransfer, reset } from "redux/admin/diamond-transfer/diamondTransferSlice";
import { MdOutlineDone, MdClear } from "react-icons/md"
import { settings } from "redux/admin/settings/settingsSlice";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import moment from "moment";
import "moment/locale/tr";

moment.locale("tr");

const DiamondTransfers = ({ data }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { settingsData } = useSelector((state) => state.adminSettings);

  useEffect(() => {
    dispatch(settings());
  }, [dispatch]);

  //filter settings data
  const baseDiamondPrice = settingsData?.filter(
    (item) => item.key === "base_diamond_price_usd"
  );



  const {
    isFinalizeTransferSuccess,
    isFinalizeTransferError,
  } = useSelector((state) => state.adminDiamondTransfer);



  const handleFinalize = (id, status) => {
    dispatch(finalizeTransfer({ transfer_id: id, status: status, message: null, transfer_reference: null }));
  };

  useEffect(() => {
    if (isFinalizeTransferSuccess) {
      toast.success("Talep Onaylandı");
      setTimeout(() => {
        navigate(0)
      }, 1000);
    }
    if (isFinalizeTransferError) {
      toast.error("Bir Hata Oluştu");
      setTimeout(() => {
        navigate(0)
      }, 1000);
    }
    return () => {
      dispatch(reset());
    };
  }, [dispatch, isFinalizeTransferSuccess, isFinalizeTransferError]);
  return (
    <div className="overflow-x-auto p-4">
      <table className="min-w-full divide-y-2 divide-gray-200 text-sm">
        <thead className="">
          <tr>
            <th className="whitespace-nowrap text-left px-4 py-2 font-medium text-white-100">
              Elmas Miktarı
            </th>
            <th className="whitespace-nowrap text-left px-4 py-2 font-medium text-white-100">
              Bayi Adı
            </th>
            <th className="whitespace-nowrap text-left px-4 py-2 font-medium text-white-100">
              TL Karşılığı
            </th>
            <th className="whitespace-nowrap text-left px-4 py-2 font-medium text-white-100">
              Dolar Karşılığı
            </th>
            <th className="whitespace-nowrap text-left px-4 py-2 font-medium text-white-100">
              İşlem Tarihi
            </th>
            <th className="whitespace-nowrap text-left px-4 py-2 font-medium text-white-100">
              İşlem Durumu
            </th>
            <th className="whitespace-nowrap text-left px-4 py-2 font-medium text-white-100">
              İşlem
            </th>
          </tr>
        </thead>

        <tbody className="divide-y divide-gray-200">
          {data?.map((data) => (
            <tr key={data.id}>
              <td className="whitespace-nowrap px-4 py-2 font-medium text-white-100">
                {
                  Intl.NumberFormat("tr-TR").format(
                      data.amount_diamonds
                  )
                }
              </td>
              <td className="whitespace-nowrap px-4 py-2 font-medium text-white-100">
                {data.reseller.name}
              </td>
              <td className="whitespace-nowrap px-4 py-2 text-white-100">
                {
                    Intl.NumberFormat("tr-TR",{
                        style: "currency",
                        currency: "TRY"
                    }).format(
                        data.amount_try
                    )
                }
              </td>

              <td className="whitespace-nowrap px-4 py-2 text-white-100">

                {
                    Intl.NumberFormat("tr-TR",{
                        style: "currency",
                        currency: "USD"
                    }).format(
                        (data.amount_diamonds * baseDiamondPrice[0]?.value).toFixed(2)
                    )
                }
              </td>

              <td className="whitespace-nowrap px-4 py-2 text-white-100">
                {moment(data.created_at).format("LLL")}
              </td>
              <td className="whitespace-nowrap px-4 py-2 text-white-100">
                {data.status === "completed" ? (
                  <OrderIndicator color="green">
                    Aktarım Yapıldı.
                  </OrderIndicator>
                ) : data.status === "pending_transfer" ? (
                  <OrderIndicator color="yellow">
                    Aktarım Bekliyor.
                  </OrderIndicator>
                ) : data.status === "mico_processing" ? (
                  <OrderIndicator color="yellow">
                    Bigo yükleniyor..
                  </OrderIndicator>
                ) : data.status === "failed" ? (
                  <OrderIndicator color="red">
                    Aktarım Reddedildi.
                  </OrderIndicator>
                ) : (
                  <OrderIndicator color="red">No Data</OrderIndicator>
                )}
              </td>
              {data.status === "pending_transfer" && (
                <td className="whitespace-nowrap px-4 py-2 text-white-100">
                  <div className="flex gap-2">
                    <span
                      onClick={() => handleFinalize(data.id, "completed")}
                      className="bg-primary text-white rounded-md p-2 flex items-center text-center font-semibold cursor-pointer"
                    >
                      <MdOutlineDone />
                    </span>
                    <span
                      onClick={() => handleFinalize(data.id, "failed")}
                      className="bg-red-500 text-white rounded-md flex items-center p-2 text-center font-semibold cursor-pointer"
                    >
                      <MdClear />
                    </span>
                  </div>
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default DiamondTransfers;
